<template>
  <div class="inner-modal">
    <div class="title">Invoice/Estimate Stats 📋</div>
    <div class="stats-list">
      <div v-for="(stat, status) in stats" :key="status" class="stat-item">
        <div class="status">{{ (status !== 'partiallyPaid') ? status.charAt(0).toUpperCase() + status.slice(1) : 'Partially Paid' }}</div>
        <div class="amount">
          Parts: <span class="amount">£{{ stat.parts.toFixed(2) }}</span>
        </div>
        <div class="amount">
          Labour: <span class="amount">£{{ stat.labour.toFixed(2) }}</span>
        </div>
        <div class="amount">
          Subtotal: <span class="amount">£{{ stat.subtotal.toFixed(2) }}</span>
        </div>
        <div class="amount">
          Total: <span class="amount">£{{ stat.total.toFixed(2) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['stats']
}
</script>

<style scoped>
  .stats-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .stat-item {
    flex: 1 0 calc(33% - 20px);
    margin: 10px;
    text-align: left;
  }
  .stat-item .status {
    font-size: 18px;
    font-weight: 800;
  }
  .stat-item span.amount {
    font-weight: 600;
  }
</style>
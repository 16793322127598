<template>
  <div class="modal show">
    <div class="inner-modal">
      <div class="title">Calculate Stats 🔢</div>
      <form @submit.prevent="handleSubmit">
        <label>Issue Date:</label>
        <input type="datetime-local" :value="startDate ? toIsoString(new Date(startDate)) : null" @change="(e) => handleDate('start', e.target.value)" required>
        <label>Due Date:</label>
        <input type="datetime-local" :value="endDate ? toIsoString(new Date(endDate)) : null" @change="(e) => handleDate('end', e.target.value)" required>
        <label>Type:</label>
        <select v-model="type">
          <option value="all">All</option>
          <option :value="false">Invoices</option>
          <option :value="true">Estimates</option>
        </select>
        <label>Status:</label>
        <select v-model="status">
          <option value="all">All</option>
          <option value="total">Total</option>
          <option value="open">Open</option>
          <option value="sent">Sent</option>
          <option value="paid">Paid</option>
          <option value="partiallyPaid">Partially Paid</option>
          <option value="cancelled">Cancelled</option>
        </select>
        <div class="buttons">
          <button v-if="!isLoading" type="submit" class="swal2-styled swal2-confirm">Get Stats</button>
          <button v-if="!isLoading" type="button" class="swal2-styled swal2-cancel" @click="handleClose">Close</button>
          <button v-else class="swal2-styled swal2-confirm" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>  
        </div>
      </form>
      <div v-if="error" class="error">{{ error }}</div>
    </div>
    <StatsListModal v-if="stats" :stats="stats" />
  </div>
</template>

<script>
import { ref } from 'vue'
import useFetch from '@/composables/useFetch'
import toIsoString from '@/composables/getIsoDate'
import StatsListModal from './StatsListModal.vue'
export default {
  components: { StatsListModal },
  setup(props, { emit }) {
    const { error, isLoading, fetchData } = useFetch()
    const stats = ref(null)
    const status = ref('total')
    const startDate = ref(Date.now() - (8.64e7 * 7)) // 7 days ago
    const endDate = ref(Date.now()) // today
    const type = ref('all')
    
    const handleDate = (date, value) => {
      if (date === 'start') {
        startDate.value = new Date(value).getTime()
      } else if (date === 'end') {
        endDate.value = new Date(value).getTime()
      }
    }
    
    const handleSubmit = async () => {
      const res = await fetchData(
        `/invoices/usage?startDate=${startDate.value}&endDate=${endDate.value}&type=${type.value}&status=${status.value}`, 
        'get'
      )
      stats.value = res.data
    }

    const handleClose = () => emit('modal-close')
    
    return { stats, status, startDate, endDate, type, error, isLoading, handleDate, 
      handleSubmit, handleClose, toIsoString }
  }
}
</script>
<template>
  <div class="page-header">
    <h3>All Invoices/Estimates</h3>
    <button @click="showStatsModal = true">Get Stats</button>
  </div>
  <div class="container">
    <div class="inner-container">
      <div class="page-subtitle">List of Invoices/Estimates 🧾</div>
      <InvoiceTable />
    </div>
  </div>
  <InvoiceStats v-if="showStatsModal" @modal-close="showStatsModal = false" />
</template>

<script>
import { ref } from 'vue'
import InvoiceStats from '@/components/Invoice/StatsModal.vue'
import InvoiceTable from '@/components/Invoice/Table.vue'

export default {
  components: { InvoiceStats, InvoiceTable },
  setup() {
    const showStatsModal = ref(false)
    return { showStatsModal }
  }
}
</script>